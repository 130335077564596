import axios from "axios";
import React from 'react';
import { BrowserRouter } from "react-router-dom";
import Main from './Main';

if (window.location.origin === "http://127.0.0.1:3000") {
  axios.defaults.baseURL = "http://127.0.0.1:8000/api/";
} else {
  axios.defaults.baseURL = `${window.location.origin}/api/`;
}



function App() {
  return (
    <BrowserRouter>
    <Main></Main>
    </BrowserRouter>
  );
}

export default App;
