import { Container } from "@material-ui/core";
import { Button, Grid, Slider, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import canule_caract from "../../assets/canule_caract.jpg";
// import { useDispatch, useSelector } from "react-redux";
import axiosQUERY from "../../queries/axiosQUERY";

function valuetext(value) {
  return `${value}mm`;
}

export default function RechercheSimple() {
  let history = useHistory();
  const [state, setState] = useState({
    marksDe: [],
    marksDi: [],
    marksLongueur_totale_utile: [],
    GAMME_CHOICES: [],
    Materiau_Ballonnet_CHOICES: [],
    Presence_chemise_interne_fournie_avec_canule_CHOICES: [],
    Presence_fenetre_CHOICES: [],
    Reference_Hopital_CHOICES: [],
  });
  const [search, setSearch] = useState({
    Di_Canule: [0, 10],
    De_Canule: [0, 10],
    Longueur_totale_utile: [0, 10],
    Gamme: [],
    Materiau_Ballonnet: [],
    Presence_chemise_interne_fournie_avec_canule: [],
    Presence_fenetre: [],
  });
  const [settings, setSettings] = useState({});
  const getParameters = () => {
    axiosQUERY({ method: "GET", url: `parameters` })
      .then((res) => {
        setState(res.data);
        setSettings((settings) => ({
          ...settings,

          marksDi: [
            {
              value: res.data.minDiCanule,
              label: `${res.data.minDiCanule}mm`,
            },
            {
              value: res.data.maxDiCanule,
              label: `${res.data.maxDiCanule}mm`,
            },
          ],
          marksDe: [
            {
              value: res.data.minDeCanule,
              label: `${res.data.minDeCanule}mm`,
            },
            {
              value: res.data.maxDeCanule,
              label: `${res.data.maxDeCanule}mm`,
            },
          ],
          marksLongueur_totale_utile: [
            {
              value: res.data.minLongueur_totale_utile,
              label: `${res.data.minLongueur_totale_utile}mm`,
            },
            {
              value: res.data.maxLongueur_totale_utile,
              label: `${res.data.maxLongueur_totale_utile}mm`,
            },
          ],
        }));

        setSearch((search) => ({
          ...search,
          Di_Canule: [res.data.minDiCanule, res.data.maxDiCanule],
          De_Canule: [res.data.minDeCanule, res.data.maxDeCanule],
          Longueur_totale_utile: [
            res.data.minLongueur_totale_utile,
            res.data.maxLongueur_totale_utile,
          ],
        }));
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  useEffect(() => {
    getParameters();
  }, []);

  const handleChange = (e) => {
    let name = e.target.id;
    let value = e.target.value;
    setSearch((search) => ({ ...search, [name]: value }));
  };

  const handleSubmit = (e) => {
    axiosQUERY({ method: "POST", data: { search }, url: `recherche-simple` })
      .then((res) => {
        history.push({
          pathname: "/liste-canules",
          state: res.data,
        });
      })
      .catch((err) => {
        console.log("error");
      });
  };

  const handleChangeSlider = (e, value) => {
    let name = e.target.id;
    setSearch((search) => ({ ...search, [name]: value }));
  };

  return state ? (
    <div>
      <form noValidate autoComplete="off">
        <br />
        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={3}>
            <Grid item sm={6}>
              <h1 className="text-center">
                Glossaire
                <br />
              </h1>
              <p>
                <ul>
                  <Image src={canule_caract} fluid></Image>
                </ul>
              </p>
            </Grid>
            <Grid item sm={6}>
              <Container>
                <h1 className="text-center">Champs de recherche :</h1>
                <br />
                <Form.Group as={Row} controlId="formGamme">
                  <Form.Label column sm="2">
                    Gamme :
                  </Form.Label>
                  <Col sm="10">
                    <Autocomplete
                      value={search.Gamme}
                      onChange={(event, newValue) => {
                        setSearch((search) => ({ ...search, Gamme: newValue }));
                      }}
                      onInputChange={(event, newValue) => {
                        setSearch((search) => ({ ...search, Gamme: newValue }));
                      }}
                      id="Gamme"
                      options={state.GAMME_CHOICES}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </Col>
                </Form.Group>
                <br />
                <Form.Group as={Row} controlId="formDiCanule">
                  <Form.Label column sm="2">
                    Référence Hôpital / Ville :
                  </Form.Label>
                  <Col sm="10">
                    <Autocomplete
                      value={search.Reference_Hopital}
                      onChange={(event, newValue) => {
                        setSearch((search) => ({
                          ...search,
                          Reference_Hopital: newValue,
                        }));
                      }}
                      onInputChange={(event, newValue) => {
                        setSearch((search) => ({
                          ...search,
                          Reference_Hopital: newValue,
                        }));
                      }}
                      id="Reference_Hopital"
                      options={state.Reference_Hopital_CHOICES}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </Col>
                </Form.Group>

                <br />
                <Form.Group as={Row} controlId="formDiCanule">
                  <Form.Label column sm="2">
                    DI Canule (mm) :
                  </Form.Label>
                  <Col sm="10">
                    <Slider
                      id="Di_Canule"
                      name="Di_Canule"
                      valueLabelDisplay="on"
                      value={search.Di_Canule}
                      onChange={handleChangeSlider}
                      step={0.2}
                      min={state.minDiCanule}
                      max={state.maxDiCanule}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      getAriaValueText={valuetext}
                      defaultValue={search.Di_Canule}
                      marks={settings.marksDi}
                      valueLabelDisplay="on"
                    />
                  </Col>
                </Form.Group>
                <br />
                <Form.Group as={Row} controlId="formDeCanule">
                  <Form.Label column sm="2">
                    DE Canule (mm) :
                  </Form.Label>
                  <Col sm="10">
                    <Slider
                      id="De_Canule"
                      name="De_Canule"
                      valueLabelDisplay="on"
                      value={search.De_Canule}
                      onChange={handleChangeSlider}
                      step={0.2}
                      min={state.minDeCanule}
                      max={state.maxDeCanule}
                      valueLabelDisplay="auto"
                      aria-labelledby="DE Canule"
                      getAriaValueText={valuetext}
                      defaultValue={search.De_Canule}
                      marks={settings.marksDe}
                      valueLabelDisplay="on"
                    />
                  </Col>
                </Form.Group>

                <br />
                <Form.Group as={Row} controlId="formLongueur_totale_utile">
                  <Form.Label column sm="2">
                    Longueur totale utile (mm) :
                  </Form.Label>
                  <Col sm="10">
                    <Slider
                      id="Longueur_totale_utile"
                      name="Longueur_totale_utile"
                      valueLabelDisplay="on"
                      value={search.Longueur_totale_utile}
                      onChange={handleChangeSlider}
                      step={2}
                      min={state.minLongueur_totale_utile}
                      max={state.maxLongueur_totale_utile}
                      valueLabelDisplay="auto"
                      aria-labelledby="Longueur_totale_utile"
                      getAriaValueText={valuetext}
                      marks={settings.marksLongueur_totale_utile}
                      valueLabelDisplay="on"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formBallonnet">
                  <Form.Label column sm="2">
                    Ballonnet :
                  </Form.Label>
                  <Col sm="10">
                    <Autocomplete
                      value={search.Materiau_Ballonnet}
                      onChange={(event, newValue) => {
                        setSearch((search) => ({
                          ...search,
                          Materiau_Ballonnet: newValue,
                        }));
                      }}
                      onInputChange={(event, newValue) => {
                        setSearch((search) => ({
                          ...search,
                          Materiau_Ballonnet: newValue,
                        }));
                      }}
                      id="Materiau_Ballonnet"
                      options={state.Materiau_Ballonnet_CHOICES}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  controlId="formPresence_chemise_interne_fournie_avec_canule"
                >
                  <Form.Label column sm="2">
                    Présence d'une chemise interne :
                  </Form.Label>
                  <Col sm="10">
                    <Autocomplete
                      value={
                        search.Presence_chemise_interne_fournie_avec_canule
                      }
                      onChange={(event, newValue) => {
                        setSearch((search) => ({
                          ...search,
                          Presence_chemise_interne_fournie_avec_canule:
                            newValue,
                        }));
                      }}
                      onInputChange={(event, newValue) => {
                        setSearch((search) => ({
                          ...search,
                          Presence_chemise_interne_fournie_avec_canule:
                            newValue,
                        }));
                      }}
                      id="Presence_chemise_interne_fournie_avec_canule"
                      options={
                        state.Presence_chemise_interne_fournie_avec_canule_CHOICES
                      }
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formPresence_fenetre">
                  <Form.Label column sm="2">
                    Présence d'une fenêtre :
                  </Form.Label>
                  <Col sm="10">
                    <Autocomplete
                      value={search.Presence_fenetre}
                      onChange={(event, newValue) => {
                        setSearch((search) => ({
                          ...search,
                          Presence_fenetre: newValue,
                        }));
                      }}
                      onInputChange={(event, newValue) => {
                        setSearch((search) => ({
                          ...search,
                          Presence_fenetre: newValue,
                        }));
                      }}
                      id="Presence_fenetre"
                      options={state.Presence_fenetre_CHOICES}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </Col>
                </Form.Group>
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  variant="contained"
                >
                  Valider
                </Button>
              </Container>{" "}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  ) : (
    <h1>Loading</h1>
  );
}
