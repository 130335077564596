import { Container } from "@material-ui/core";
import { Button, Grid, Slider, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import canule_caract from "../../assets/canule_caract.jpg";
// import { useDispatch, useSelector } from "react-redux";
import axiosQUERY from "../../queries/axiosQUERY";

function valuetext(value) {
  return `${value}mm`;
}

export default function RechercheAvancee() {
  let history = useHistory();
  const [state, setState] = useState({
    marksDe: [],
    marksDi: [],
    marksLongueur_totale_utile: [],
    GAMME_CHOICES: [],
    Materiau_Ballonnet_CHOICES: [],
    Presence_chemise_interne_fournie_avec_canule_CHOICES: [],
    Presence_fenetre_CHOICES: [],
    Reference_Hopital_CHOICES: [],
    FOURNISSEUR_CHOICES: [],
    Type_de_ballonnet_CHOICES: [],
    Gonflage_CHOICES: [],
  });
  const [search, setSearch] = useState({
    Di_Canule: [0, 10],
    De_Canule: [0, 10],
    Longueur_totale_utile: [0, 10],
    Gamme: [],
    Materiau_Ballonnet: [],
    Presence_chemise_interne_fournie_avec_canule: [],
    Presence_fenetre: [],
    Fournisseur: [],
  });
  const [settings, setSettings] = useState({});
  const getParameters = () => {
    axiosQUERY({ method: "GET", url: `parameters` })
      .then((res) => {
        setState(res.data);
        setSettings((settings) => ({
          ...settings,

          marksDi: [
            {
              value: res.data.minDiCanule,
              label: `${res.data.minDiCanule}mm`,
            },
            {
              value: res.data.maxDiCanule,
              label: `${res.data.maxDiCanule}mm`,
            },
          ],
          marksDe: [
            {
              value: res.data.minDeCanule,
              label: `${res.data.minDeCanule}mm`,
            },
            {
              value: res.data.maxDeCanule,
              label: `${res.data.maxDeCanule}mm`,
            },
          ],
          marksLongueur_totale_utile: [
            {
              value: res.data.minLongueur_totale_utile,
              label: `${res.data.minLongueur_totale_utile}mm`,
            },
            {
              value: res.data.maxLongueur_totale_utile,
              label: `${res.data.maxLongueur_totale_utile}mm`,
            },
          ],
        }));

        setSearch((search) => ({
          ...search,
          Di_Canule: [res.data.minDiCanule, res.data.maxDiCanule],
          De_Canule: [res.data.minDeCanule, res.data.maxDeCanule],
          Longueur_totale_utile: [
            res.data.minLongueur_totale_utile,
            res.data.maxLongueur_totale_utile,
          ],
        }));
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  useEffect(() => {
    getParameters();
  }, []);

  const handleChange = (e) => {
    let name = e.target.id;
    let value = e.target.value;
    setSearch((search) => ({ ...search, [name]: value }));
  };

  const handleSubmit = (e) => {
    axiosQUERY({ method: "POST", data: { search }, url: `recherche-avancee` })
      .then((res) => {
        history.push({
          pathname: "/liste-canules",
          state: res.data,
        });
      })
      .catch((err) => {
        console.log("error");
      });
  };

  const handleChangeSlider = (e, value) => {
    let name = e.target.id;
    setSearch((search) => ({ ...search, [name]: value }));
  };

  return state ? (
    <div>
        <Container>
      <form noValidate autoComplete="off">
        <br />
        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={3}>
            <Grid item sm={12}>
              <h1 className="text-center">
                Glossaire
                <br />
              </h1>
              <p>
                <ul>
                  <Image
                    src={canule_caract}
                    fluid
                    className="rounded mx-auto d-block"
                  ></Image>
                </ul>
              </p>
              <h1 className="text-center">Champs de recherche :</h1>
              <br />
              <Form.Group as={Row} controlId="formGamme">
                <Form.Label column sm="2">
                  Gamme :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Gamme}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({ ...search, Gamme: newValue }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({ ...search, Gamme: newValue }));
                    }}
                    id="Gamme"
                    options={state.GAMME_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>
              <br />
              <Form.Group as={Row} controlId="formFournisseur">
                <Form.Label column sm="2">
                  Fournisseur :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Fournisseur}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Fournisseur: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Fournisseur: newValue,
                      }));
                    }}
                    id="Fournisseur"
                    options={state.FOURNISSEUR_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>
              <br />
              <Form.Group as={Row} controlId="formDiCanule">
                <Form.Label column sm="2">
                  Référence Hôpital / Ville :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Reference_Hopital}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Reference_Hopital: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Reference_Hopital: newValue,
                      }));
                    }}
                    id="Reference_Hopital"
                    options={state.Reference_Hopital_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>

              <br />
              <Form.Group as={Row} controlId="formDiCanule">
                <Form.Label column sm="2">
                  DI Canule (mm) :
                </Form.Label>
                <Col sm="10">
                  <Slider
                    id="Di_Canule"
                    name="Di_Canule"
                    valueLabelDisplay="on"
                    value={search.Di_Canule}
                    onChange={handleChangeSlider}
                    step={0.2}
                    min={state.minDiCanule}
                    max={state.maxDiCanule}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                    defaultValue={search.Di_Canule}
                    marks={settings.marksDi}
                    valueLabelDisplay="on"
                  />
                </Col>
              </Form.Group>
              <br />

              <Form.Group as={Row} controlId="formDI_Chemise_interne">
                <Form.Label column sm="2">
                  DI interne de la chemise interne (mm) :
                </Form.Label>
                <Col sm="10">
                  <TextField
                    id="DI_Chemise_interne"
                    name="DI_Chemise_interne"
                    value={search.DI_Chemise_interne}
                    onChange={handleChange}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </Col>
              </Form.Group>
              <br />

              <Form.Group as={Row} controlId="formDeCanule">
                <Form.Label column sm="2">
                  DE Canule (mm) :
                </Form.Label>
                <Col sm="10">
                  <Slider
                    id="De_Canule"
                    name="De_Canule"
                    valueLabelDisplay="on"
                    value={search.De_Canule}
                    onChange={handleChangeSlider}
                    step={0.2}
                    min={state.minDeCanule}
                    max={state.maxDeCanule}
                    valueLabelDisplay="auto"
                    aria-labelledby="DE Canule"
                    getAriaValueText={valuetext}
                    defaultValue={search.De_Canule}
                    marks={settings.marksDe}
                    valueLabelDisplay="on"
                  />
                </Col>
              </Form.Group>

              <br />

              <Form.Group as={Row} controlId="formLongueur_raccord_collerette">
                <Form.Label column sm="2">
                  Longueur raccord-collerette (mm) :
                </Form.Label>
                <Col sm="10">
                  <TextField
                    id="Longueur_raccord_collerette"
                    name="Longueur_raccord_collerette"
                    value={search.Longueur_raccord_collerette}
                    onChange={handleChange}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </Col>
              </Form.Group>
              <br />

              <Form.Group as={Row} controlId="formLongueur_totale_utile">
                <Form.Label column sm="2">
                  Longueur totale utile (mm) :
                </Form.Label>
                <Col sm="10">
                  <Slider
                    id="Longueur_totale_utile"
                    name="Longueur_totale_utile"
                    valueLabelDisplay="on"
                    value={search.Longueur_totale_utile}
                    onChange={handleChangeSlider}
                    step={2}
                    min={state.minLongueur_totale_utile}
                    max={state.maxLongueur_totale_utile}
                    valueLabelDisplay="auto"
                    aria-labelledby="Longueur_totale_utile"
                    getAriaValueText={valuetext}
                    marks={settings.marksLongueur_totale_utile}
                    valueLabelDisplay="on"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formLong_A">
                <Form.Label column sm="2">
                  Longueur du segment A (mm) :
                </Form.Label>
                <Col sm="10">
                  <TextField
                    id="Long_A"
                    name="Long_A"
                    value={search.Long_A}
                    onChange={handleChange}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </Col>
              </Form.Group>
              <br />

              <Form.Group as={Row} controlId="formLong_B">
                <Form.Label column sm="2">
                  Longueur du segment B (mm) :
                </Form.Label>
                <Col sm="10">
                  <TextField
                    id="Long_B"
                    name="Long_B"
                    value={search.Long_B}
                    onChange={handleChange}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </Col>
              </Form.Group>
              <br />

              <Form.Group as={Row} controlId="formLong_C">
                <Form.Label column sm="2">
                  Longueur du segment C (mm) :
                </Form.Label>
                <Col sm="10">
                  <TextField
                    id="Long_C"
                    name="Long_C"
                    value={search.Long_C}
                    onChange={handleChange}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </Col>
              </Form.Group>
              <br />

              <Form.Group as={Row} controlId="formAngle">
                <Form.Label column sm="2">
                  Angle (°) :
                </Form.Label>
                <Col sm="10">
                  <TextField
                    id="Angle"
                    name="Angle"
                    value={search.Angle}
                    onChange={handleChange}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </Col>
              </Form.Group>
              <br />

              <Form.Group as={Row} controlId="formBallonnet">
                <Form.Label column sm="2">
                  Ballonnet :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Materiau_Ballonnet}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Materiau_Ballonnet: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Materiau_Ballonnet: newValue,
                      }));
                    }}
                    id="Materiau_Ballonnet"
                    options={state.Materiau_Ballonnet_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>

              <br />
              <Form.Group as={Row} controlId="formTypeBalonnet">
                <Form.Label column sm="2">
                  Type de ballonnet :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Type_de_ballonnet}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Type_de_ballonnet: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Type_de_ballonnet: newValue,
                      }));
                    }}
                    id="Type_de_ballonnet"
                    options={state.Type_de_ballonnet_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formDiametre_ballonnet">
                <Form.Label column sm="2">
                  DI ballonnet (mm) :
                </Form.Label>
                <Col sm="10">
                  <TextField
                    id="Diametre_ballonnet"
                    name="Diametre_ballonnet"
                    value={search.Diametre_ballonnet}
                    onChange={handleChange}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </Col>
              </Form.Group>
              <br />

              <Form.Group as={Row} controlId="formGonflage">
                <Form.Label column sm="2">
                  Gonflage :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Gonflage}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Gonflage: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Gonflage: newValue,
                      }));
                    }}
                    id="Gonflage"
                    options={state.Gonflage_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>
              <br />

              <Form.Group
                as={Row}
                controlId="formReference_de_la_chemise_interne"
              >
                <Form.Label column sm="2">
                  Référence de la chemise interne :
                </Form.Label>
                <Col sm="10">
                  <TextField
                    id="Reference_de_la_chemise_interne"
                    name="Reference_de_la_chemise_interne"
                    value={search.Reference_de_la_chemise_interne}
                    onChange={handleChange}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </Col>
              </Form.Group>
              <br />

              <Form.Group as={Row} controlId="formArmee">
                <Form.Label column sm="2">
                  Armée :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Armee}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Armee: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Armee: newValue,
                      }));
                    }}
                    id="Armee"
                    options={state.Armee_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>
              <br />

              <Form.Group as={Row} controlId="formMateriaux_canule">
                <Form.Label column sm="2">
                  Matériaux de la canule :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Materiaux_canule}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Materiaux_canule: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Materiaux_canule: newValue,
                      }));
                    }}
                    id="Materiaux_canule"
                    options={state.Materiaux_canule_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>
              <br />

              <Form.Group as={Row} controlId="formPresence_fenetre">
                <Form.Label column sm="2">
                  Présence d'une fenêtre :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Presence_fenetre}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Presence_fenetre: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Presence_fenetre: newValue,
                      }));
                    }}
                    id="Presence_fenetre"
                    options={state.Presence_fenetre_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>
              <br />

              <Form.Group as={Row} controlId="formType_fenetre">
                <Form.Label column sm="2">
                  Type fenêtre :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Type_fenetre}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Type_fenetre: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Type_fenetre: newValue,
                      }));
                    }}
                    id="Type_fenetre"
                    options={state.Type_fenetre_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>

              <br />

              <Form.Group
                as={Row}
                controlId="formPresence_chemise_interne_fournie_avec_canule"
              >
                <Form.Label column sm="2">
                  Présence d'une chemise interne :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Presence_chemise_interne_fournie_avec_canule}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Presence_chemise_interne_fournie_avec_canule: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Presence_chemise_interne_fournie_avec_canule: newValue,
                      }));
                    }}
                    id="Presence_chemise_interne_fournie_avec_canule"
                    options={
                      state.Presence_chemise_interne_fournie_avec_canule_CHOICES
                    }
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>

              <br />
              <Form.Group as={Row} controlId="formType_de_chemise">
                <Form.Label column sm="2">
                  Type de chemise :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Type_de_chemise}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Type_de_chemise: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Type_de_chemise: newValue,
                      }));
                    }}
                    id="Type_de_chemise"
                    options={state.Type_de_chemise_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>

              <br />
              <Form.Group as={Row} controlId="formCompatible_IRM">
                <Form.Label column sm="2">
                  Compatible IRM :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Compatible_IRM}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Compatible_IRM: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Compatible_IRM: newValue,
                      }));
                    }}
                    id="Compatible_IRM"
                    options={state.Compatible_IRM_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>

              <br />
              <Form.Group as={Row} controlId="formType_Raccord">
                <Form.Label column sm="2">
                  Type Raccord (mm) :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Type_Raccord}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Type_Raccord: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Type_Raccord: newValue,
                      }));
                    }}
                    id="Type_Raccord"
                    options={state.Type_Raccord_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>
              <br />
              <Form.Group as={Row} controlId="formForme_collerette">
                <Form.Label column sm="2">
                  Forme collerette :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Forme_collerette}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Forme_collerette: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Forme_collerette: newValue,
                      }));
                    }}
                    id="Forme_collerette"
                    options={state.Forme_collerette_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>

              <br />
              <Form.Group as={Row} controlId="formMode_de_sterilisation">
                <Form.Label column sm="2">
                  Mode de stérilisation :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Mode_de_sterilisation}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Mode_de_sterilisation: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Mode_de_sterilisation: newValue,
                      }));
                    }}
                    id="Mode_de_sterilisation"
                    options={state.Mode_de_sterilisation_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>

              <br />
              <Form.Group
                as={Row}
                controlId="formPresence_ligne_aspiration_au_dessus_du_ballonnet"
              >
                <Form.Label column sm="2">
                  Présence ligne aspiration au dessus du ballonnet :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={
                      search.Presence_ligne_aspiration_au_dessus_du_ballonnet
                    }
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Presence_ligne_aspiration_au_dessus_du_ballonnet:
                          newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Presence_ligne_aspiration_au_dessus_du_ballonnet:
                          newValue,
                      }));
                    }}
                    id="Presence_ligne_aspiration_au_dessus_du_ballonnet"
                    options={
                      state.Presence_ligne_aspiration_au_dessus_du_ballonnet_CHOICES
                    }
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>

              <br />
              <Form.Group as={Row} controlId="formPresence_de_phtalates">
                <Form.Label column sm="2">
                  Présence des phtalates :
                </Form.Label>
                <Col sm="10">
                  <Autocomplete
                    value={search.Presence_de_phtalates}
                    onChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Presence_de_phtalates: newValue,
                      }));
                    }}
                    onInputChange={(event, newValue) => {
                      setSearch((search) => ({
                        ...search,
                        Presence_de_phtalates: newValue,
                      }));
                    }}
                    id="Presence_de_phtalates"
                    options={state.Presence_de_phtalates_CHOICES}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Col>
              </Form.Group>

              <br />

              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
              >
                Valider
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      </Container>
    </div>
  ) : (
    <h1>Loading</h1>
  );
}
