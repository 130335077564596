import { CircularProgress } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  multiSelectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import { useHistory, useLocation } from "react-router";
// import { useDispatch, useSelector } from "react-redux";
import axiosQUERY from "../../queries/axiosQUERY";
function ListeCanule() {
  const history = useHistory();
  const [state, setState] = useState();
  const location = useLocation();

  const columnsCanule = [
    {
      dataField: "NSFP",
      text: "NSFP",
    },
    {
      dataField: "Fournisseur",
      text: "Fournisseur",
    },
    {
      dataField: "Reference_Hopital",
      text: "Référence Hôpital/Ville",
    },
    {
      dataField: "Di_Canule",
      text: "DI Canule (mm)",
    },

    {
      dataField: "De_Canule",
      text: "DE Canule (mm)",
    },
    {
      dataField: "Longueur_totale_utile",
      text: "Longueur totale utile (mm)",
    },
    {
      dataField: "Presence_fenetre",
      text: "Fenêtre",
    },
    {
      dataField: "Materiau_Ballonnet",
      text: "Ballonnet",
    },

    {
      dataField: "actions",
      text: "Fiche technique",
      formatter: (rowContent, row) => {
        const onGetCanule = () => {
          history.push({
            pathname: "/canule",
            state: row,
          });
        };
        return (
          <div>
            <Button
              variant="contained"
              onClick={onGetCanule}
              color="primary"
              size="small"
            >
              Détails
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setState(location.state);
  }, [location]);

  return state ? (
    <Grid container direction="column" justify="center" alignItems="center">
      <h1 className="text-center">
        Nombre de canules trouvées : {state.length}{" "}
      </h1>
      <br />
      <Grid item > 
      <BootstrapTable
        keyField="id"
        data={state}
        columns={columnsCanule}
        striped
        filter={filterFactory()}
        wrapperClasses="table-responsive"
      />
      </Grid>
    </Grid>
  ) : (
    <CircularProgress />
  );
}

export default ListeCanule;
