import { Grid } from "@material-ui/core";
import React from "react";
import { Container, Jumbotron } from "react-bootstrap";

export default function Home() {
  return (
    <div>
      <Grid   container direction="column" justify="center" alignItems="center">
        <Jumbotron >
          <Container>
            <h1 className="text-center">
              Pourquoi utiliser Garch-ATTRAC ?<br />
            </h1>
            <h5  className="text-justify">
              Grâce à cette base de données répertoriant toutes les canules
              commercialisées en France sur la base de 33 critères de choix,
              vous pourrez trouver la canule dont vous avez besoin en quelques
              clics !
            </h5>
          </Container>
        </Jumbotron>
      </Grid>
    </div>
  )}
