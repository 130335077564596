import { Box } from "@material-ui/core";
import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap/";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import logo from "./assets/favicon.png";
import ListeCanule from "./components/Canule/ListeCanule";
import RechercheAvancee from "./components/Canule/RechercheAvancee";
import RechercheSimple from "./components/Canule/RechercheSimple";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Informations from "./components/Informations";
import QuiSommes from "./components/QuiSommes";
import banniere from "./assets/wallpaper_canule.jpg";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import FicheCanule from "./components/Canule/FicheCanule";

const styles = {
  heroContainer: {
    height: "500px",
    backgroundImage: `url(${banniere})`,
    backgroundSize: "auto",
    backgroundPosition: "50%",
    width: `100%`,
    // margin: -24,
    // padding: 24,
  },
};

function Main() {
  let history = useHistory();
  const location = useLocation();

  const rechercheSubmit = (e) => {
    history.push({
      pathname: "/recherche-simple",
    });
  };

  return (
    <main>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        sticky="top"
      >
        <Navbar.Brand href="/">
          <img
            alt=""
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          Garch-ATTRAC
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">Accueil</Nav.Link>
            <Nav.Link href="/qui-sommes-nous/">Qui-sommes-nous ?</Nav.Link>
            <Nav.Link href="/informations/">Informations</Nav.Link>
            <NavDropdown title="Rechercher une canule" id="basic-nav-dropdown">
              <NavDropdown.Item href="/recherche-simple/">
                Recherche simple
              </NavDropdown.Item>
              <NavDropdown.Item href="/recherche-avancee/">
                Recherche avancée
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link  href='mailto:kamel.hamami@aphp.fr,muriel.farcy@aphp.fr'>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {location.pathname === "/recherche-simple/" ||
      location.pathname === "/recherche-avancee/" ?(<div></div>)  :(
        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={styles.heroContainer}
          >
            <Grid item>
              <h1 style={{ backgroundColor: "#212529", color: "white" }}>
                BASE DE DONNÉES DE CANULES DE TRACHÉOTOMIE
              </h1>
            </Grid>

            <Grid item>
              <Button
                onClick={rechercheSubmit}
                color="primary"
                variant="contained"
              >
                {" "}
                Commencer une recherche
              </Button>
            </Grid>
          </Grid>
          <Box m={20}></Box>
        </div>
      )}

      <Switch>
        <Route path="/home/" component={Home}></Route>
        <Route path="/qui-sommes-nous/" component={QuiSommes}></Route>
        <Route path="/informations/" component={Informations}></Route>
        <Route path="/liste-canules/" component={ListeCanule}></Route>
        <Route path="/recherche-simple/" component={RechercheSimple}></Route>
        <Route path="/recherche-avancee/" component={RechercheAvancee}></Route>
        <Route path="/canule/" component={FicheCanule}></Route>


        <Route>
          <Redirect to="/home/" />{" "}
        </Route>
      </Switch>
      <Box m={25}></Box>
      <Footer></Footer>
    </main>
  );
}

export default Main;
