import axios from "axios";

if (window.location.origin === "http://127.0.0.1:3000") {
  axios.defaults.baseURL = "http://127.0.0.1:8000/api/";
} else {
  axios.defaults.baseURL = `${window.location.origin}/api/`;
}

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
  
    if (
      error.response.status === 401 &&
      error.response.statusText === "Unauthorized"
    ) {
      return "Promise.reject(error)";

    }
  }
);
export default axiosInstance;
