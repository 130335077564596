import axiosInstance from "../queries/axiosAPI";


export default function axiosQUERY({ method, url, data }) {
  return axiosInstance({
    method : method,
   
    url: `${url}`,
    data: data,
  });

}
