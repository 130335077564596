import { Paper } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { CircularProgress, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
function FicheCanule() {
  const [state, setState] = useState();
  const location = useLocation();

  useEffect(() => {
    setState(location.state);
    console.log(location.state)
  }, [location]);

  const onDownload = () => {
    const link = document.createElement("a");
    // link.download = "img.jpg";
    // link.href = "/static/media/wallpaper_canule.73d4db96.jpg";
    link.click();
  };

  return state ? (
      <Container>
    <Paper>
      <h1 className="text-center">{state.Denomination_commerciale}</h1>
      <br />
      <h2 className="text-center" style={{ color: "red" }}>
        <strong>{state.NSFP}</strong>
      </h2>
      <br />
      <Grid container justify="center"
  alignItems="center" spacing={1}>
        <Grid container item xs={12} spacing={3}>
          <Grid item sm={6}>
            <ul>
              <li>
                <strong>Gamme :</strong> {state.Gamme}
              </li>
              <li>
                <strong>Référence Hôpital / Ville :</strong> {state.Reference_Hopital}
              </li>
              {/* <li>
                <strong>Référence Ville :</strong> {state.Reference_Ville}
              </li> */}
              <li>
                <strong>DI de la canule :</strong> {state.Di_Canule} mm
              </li>
              <li>
                <strong>DE de la canule :</strong> {state.De_Canule} mm
              </li>
              <li>
                <strong>Longueur raccord-collerette :</strong>{" "}
                {state.Longueur_raccord_collerette} mm
              </li>
              <li>
                <strong>Longueur totale utile :</strong>{" "}
                {state.Longueur_totale_utile} mm
              </li>
              <li>
                <strong>Longueur du segment A :</strong> {state.Long_A} mm
              </li>
              <li>
                <strong>Longueur du segment B :</strong> {state.Long_B} mm
              </li>
              <li>
                <strong>Longueur du segment C :</strong> {state.Long_C} mm
              </li>
              <li>
                <strong>Angle :</strong> {state.Angle}°
              </li>
              <li>
                <strong>Ballonnet :</strong> {state.Materiau_Ballonnet}
              </li>
              <li>
                <strong>Type de ballonnet :</strong>
                {state.Type_de_ballonnet}
              </li>
              <li>
                <strong>DI ballonnet :</strong> {state.Diametre_ballonnet} mm
              </li>
              <li>
                <strong>Gonflage :</strong> {state.Gonflage}
              </li>
              <li>
                <strong>Référence de la chemise interne :</strong>{" "}
                {state.Reference_de_la_chemise_interne}
              </li>
            </ul>
          </Grid>
          <Grid item sm={6}>
            <ul>
              <li>
                <strong>Armée :</strong> {state.Armee}
              </li>
              <li>
                <strong>Matériaux de la canule :</strong>{" "}
                {state.Materiaux_canule}
              </li>
              <li>
                <strong>Présence des phtalates :</strong>{" "}
                {state.Presence_de_phtalates}
              </li>
              <li>
                <strong>
                  Présence ligne aspiration au dessus du ballonnet :
                </strong>{" "}
                {state.Presence_ligne_aspiration_au_dessus_du_ballonnet} mm
              </li>
              <li>
                <strong>Présence fenêtre :</strong> {state.Presence_fenetre} mm
              </li>
              <li>
                <strong>Type fenetre :</strong> {state.Type_fenetre} mm
              </li>
              <li>
                <strong>Présence chemise interne fournie avec canule :</strong>{" "}
                {state.Presence_chemise_interne_fournie_avec_canule} mm
              </li>
              <li>
                <strong>Type de chemise :</strong> {state.Type_de_chemise} mm
              </li>
              <li>
                <strong>Compatible IRM :</strong> {state.Compatible_IRM} mm
              </li>
              <li>
                <strong>Marquage CE :</strong> {state.Marquage_CE} mm
              </li>
              <li>
                <strong>Type Raccord (mm) :</strong> {state.Type_Raccord}°
              </li>
              <li>
                <strong>Forme collerette :</strong>
                {state.Forme_collerette}
              </li>
              <li>
                <strong>Stérile :</strong> {state.presence_sterilisation} mm
              </li>
              <li>
                <strong>Mode de stérilisation :</strong>{" "}
                {state.Mode_de_sterilisation}
              </li>
              <li>
                <strong>Particularite :</strong> {state.Particularite}
              </li>
            </ul>
          </Grid>
        </Grid>
        {/* {state.Fiche_technique !== "/media/fiche_technique/" ? (<div></div>)
        : (
        <Button
              variant="contained"
              onClick={onDownload}
              color="primary"
              size="small"
            >
              Fiche technique
            </Button>)} */}
      </Grid>
    </Paper>
    </Container>
  ) : (
    <CircularProgress />
  );
}

export default FicheCanule;
