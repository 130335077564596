import { Grid } from "@material-ui/core";
import React from "react";
import { Container, Jumbotron } from "react-bootstrap";

export default function QuiSommes() {
  return (
    <Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
  >
      <Jumbotron >
        <Container>
          <h1 className="text-center">Qui sommes-nous ?</h1>
          <br />
          <p className="text-justify">
            Garch-ATTRAC est née d'une base de données constituée à partir du
            croisement entre les différentes données techniques transmises par
            les fournisseurs et les mentions figurant sur les boites de
            conditionnement des canules.
          </p>
          <p>Cette application a pour but de vous permettre de :</p>

          <ul>
            <li>
              comparer les différentes canules sur la base de critères et de
              dimensions-mesurées de façon identique
            </li>
            <li>
              recherche des canules répondant au profil anatomique du patient
            </li>
            <li>
              connaître les caractéristiques précises d'une canule portée par un
              patient
            </li>
          </ul>
          <br />
          <p>
            Mr Amyn JAAFAR ingénieur biomédical et Mme Sandra POTTIER directrice
            de la Fondation Garches, nous ont permis de transformer cette base
            de données en un outil plus conviviale afin de le mettre à
            disposition de tous
          </p>
          <br />
          <p>
            Dr Kamel HAMAMI & Dr Muriel FARCY-AFIF (Pharmaciens,Unité de DMS de
            l'Hôpital Raymond-Poincaré à Garches)
          </p>
        </Container>
      </Jumbotron>
    </Grid>
  );
}
