import { Grid } from "@material-ui/core";
import React from "react";
import { Image } from "react-bootstrap";
import canule_caract from "../assets/canule_caract.jpg";
export default function Informations() {
  return (
    <div>
      <h1 className="text-center">
        Vous trouverez ci-dessous quelques précisions concernant les critères de
        choix :<br />
      </h1>
      <br />
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={3}>
          <Grid item sm={6}>
            <p>
              <ul>
                <li>Fournisseur : Société distribuant en France</li><br/>
                <li>Gamme : Nom de marque (exemple : Shiley)</li><br/>
                <li>
                  Dénomination commerciale : Dénomination spécifique de la
                  canule
                </li><br/>
                <li>
                  Référence (Hôpital et/ou Ville) : Attention certaines canules
                  ont des références différentes en fonction de leur circuit de
                  distribution notamment les canules distribuées par Teleflex
                </li><br/>
                <li>
                  DI de la canule : Diamètre interne en mm de la canule mesuré à
                  son extremité distale (figure parfois sur les collerettes de
                  canules sous la mention ID pour Inside Diameter*, peut
                  correspondre à la taille de la canule){" "}
                </li><br/>
                <li>
                  ⚠ Dans le cas des canules avec chemise interne, l'information
                  du diamètre interne de la canule est rarement disponible. Par
                  défaut, le diamètre de la chemise interne a été renseigné dans
                  la colonne DI pour faciliter les recherches (les colonnes DI
                  de la canule et DI de la chemise interne sont alors
                  identiques)
                </li><br/>
                <li>
                  DI interne de la chemise interne : Diamètre interne en mm de
                  la chemise interne mesuré à son extremité distale (figure
                  parfois sur les collerettes de canules sous la mention ID pour
                  Inside Diameter*, peut correspondre à la taille de la canule)
                </li><br/>
                <li>
                  DE de la canule : Diamètre externe en mm de la canule mesuré
                  au niveau de la collerette (figure parfois sur les collerettes
                  de canules sous la mention OD pour Outside Diameter*)
                </li><br/>
                <li>
                  ⚠ Certains fournisseurs font apparaitre sur leur documentation
                  le diamètre externe de la canule pris au niveau de l’extrémité
                  distale de la canule or celui-ci y est parfois bien plus petit
                  qu’au niveau de la collerette
                </li><br/>
                <li>
                  Longueur raccord-collerette : Longueur en mm mesurée entre le
                  raccord Claude Bernard et la collerette (cas des canules
                  rallongées en externe)
                </li><br/>
                <p>
                  <Image src={canule_caract} fluid></Image>
                </p>
                <li>
                  Longueur totale utile : Longueur en mm mesurée de la
                  collerette à l'extremité distale de la canule (ligne de mesure
                  située au centre de la canule soit addition des segments
                  A+B+C)
                </li><br/>
                <li>
                  ⚠ Chez quelques fournisseurs, la longueur est mesurée non pas
                  sur la ligne de mesure située au centre de la canule mais sur
                  la longueur externe supérieure de la canule. A défaut de la
                  longueur utile totale, c'est cette longueur qui apparaît
                </li><br/>
                <li>
                  Longueur du segment A : Segment intrachéale de la canule en mm
                </li><br/>
                <li>
                  Longueur du segment B : Segment extrachéale de la canule en mm
                </li><br/>
                <li>
                  Longueur du segment C : Segment radiale (courbure) de la
                  canule en mm
                </li><br/>
              </ul>
            </p>
          </Grid>
          <Grid item sm={6}>
            <ul>
              <li>Angle : Courbure de la canule exprimée en degré °</li><br/>
              <li>
                Ballonnet : Type de ballonnet
                <ul>
                  <li>BP : Basse Pression</li>
                  <li>HP : Haute Pression ou Normopression</li>
                  <li>PA : Pression atmosphérique = Ballonnet à mousse</li>
                  <li>Non : Absence de ballonnet</li>
                </ul>
              </li><br/>
              <li>
                Type de ballonnet : Nom commercial de ballonnet
                <ul>
                  <li>
                    Aire-cuf® : Ballonnet Basse pression / Ballonnet témoin bleu
                    (Smiths Médical)
                  </li>
                  <li>Fit-cuf® : Ballonnet Haute Pression (Collin ORL)</li>
                  <li>
                    Fome-cuf® : Ballonnet à mousse / Ballonnet témoin de couleur
                    rouge (Smiths Médical)
                  </li>
                  <li>
                    Hi-Lo® : «High volume-Low pressure» Ballonnet basse pression
                    / Grand volume (Medtronic)
                  </li>
                  <li>
                    Hi-Lo/Lanz® : Ballonnet basse pression / Grand volume /
                    Ballonnet de régulation de pression: système Lanz
                    (Medtronic)
                  </li>
                  <li>
                    Mucoprotect® : Ballonnet basse pression / Forme allongée
                    (Collin)
                  </li>
                  <li>
                    Sealguard® : Ballonnet piriforme / Basse pression
                    (Medtronic)
                  </li>
                  <li>
                    Soft-seal® : Ballonnet basse pression / Grand volume (Smiths
                    médical)
                  </li>
                  <li>Taperguardl® : Ballonnet piriforme / (Medtronic)</li>
                  <li>
                
                    TTS® : «Tight-To-Shaft» Ballonnet haute pression / Petit
                    volume / Plaqué déglongé / Gonflé à l'eau / Ballonnet témoin
                    transprent (Smiths Médical)
                  </li>
                </ul>
              </li><br/>
              <li>Diamètre ballonnet : Diamètre du ballonnet gonflé en mm</li><br/>
              <li>
                Gonflage : Modalités de gonflage des ballonnets (hors ballonnet
                en mousse) à l'air ou à l'eau
              </li><br/>
              <li>
                Référence de la chemise interne : Cas des chemises internes
                pouvant être achetées séparement des canules
              </li><br/>
              <li>
                Armée : Présence d'une armature métallique dans le corps de la
                canule (Attention aux risques d'incompatibilité avec le passage
                en IRM)
              </li><br/>
              <li>
                Matériaux : PVC Polychlorure de Vinyle, PUR Polyuréthane, PTFE
                Polytetrafluoroethylene (=Teflon), Silicone
              </li><br/>
              <li>
                {" "}
                Présence des phtalates : Utilisés comme plastifiants dans
                l'industrie des matières plastiques, certains phtalates sont
                connus pour leur toxicité et sont classés avec les produits
                cancérogènes, mutagènes et toxiques pour la reproduction (CMR)
                de catégorie 2
              </li><br/>
              <li>
                Durée de port : Il s'agit de données du fabricant basées sur la
                classe de marquage CE de ces dispositifs IIa et IIb
              </li><br/>
              <li>
                Raccord : Présence ou non d'un raccord Claude Bernard, diamètre
                15mm (voir 22mm)
              </li><br/>
              <li>
                Collerette : Partie visible de la canule permettant notamment sa
                fixation et contenant des informations devant permettre son
                identification
              </li><br/>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
